var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "org-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.PageName))]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { staticClass: "page-menu-sider", attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: _vm.PageName, bordered: false } },
                    [
                      _c("org-tree-component", {
                        attrs: { "group-id": _vm.GroupId },
                        on: { select: _vm.treeSelect },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "table-query-card",
                        attrs: { title: "成员查询" },
                      },
                      [
                        _c("jtl-table-query-component", {
                          ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                          on: { search: _vm.searchClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-card",
                      { attrs: { title: "成员列表" } },
                      [
                        _c(
                          "template",
                          { slot: "extra" },
                          [
                            _vm.IsCorp
                              ? _c(
                                  "jtl-button",
                                  {
                                    staticClass: "table-header-button",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addCorpClick()
                                      },
                                    },
                                  },
                                  [_vm._v("+ 添加成员")]
                                )
                              : _c(
                                  "jtl-button",
                                  {
                                    staticClass: "table-header-button",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.appendClick()
                                      },
                                    },
                                  },
                                  [_vm._v("+ 添加成员")]
                                ),
                          ],
                          1
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.tableColumns,
                            "data-source": _vm.listData,
                            pagination: _vm.Pagination,
                            scroll: _vm.tableScroll,
                            "row-key": "id",
                            size: _vm.TableSize,
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u([
                            {
                              key: "name",
                              fn: function (text, record) {
                                return [
                                  _c("span", { attrs: { id: record.id } }, [
                                    _vm._v(_vm._s(record.name)),
                                  ]),
                                  _vm._v("   "),
                                  _vm.orgHeadIds &&
                                  _vm.orgHeadIds.indexOf(record.id) > -1
                                    ? _c("a-icon", {
                                        staticStyle: { color: "blue" },
                                        attrs: { type: "user" },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "status",
                              fn: function (text) {
                                return [
                                  _c(
                                    "a-tag",
                                    {
                                      attrs: { color: text ? "green" : "red" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(text ? "正常" : "禁用") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "action",
                              fn: function (text, record) {
                                return [
                                  _vm.IsCorp
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.resetPwdClick(
                                                    record
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("重置密码")]
                                          ),
                                          _c("a-divider", {
                                            attrs: { type: "vertical" },
                                          }),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "jtl-edit-link",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editClick(
                                                    record,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "jtl-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.userViewClick(record)
                                              },
                                            },
                                          },
                                          [_vm._v("查看")]
                                        ),
                                      ]),
                                  !_vm.IsCorp &&
                                  record.orgIds &&
                                  _vm.SelectGroupId
                                    ? [
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title:
                                                "确认" +
                                                (_vm.IsCorp
                                                  ? "删除"
                                                  : "移出职能部门") +
                                                "?",
                                              "ok-text": "确认",
                                              "cancel-text": "取消",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.onDeleteClick(record)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              { staticClass: "jtl-del-link" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.IsCorp
                                                      ? "删除"
                                                      : "移出职能部门"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("org-user-add-component", {
        ref: "formAppendDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("user-pwd-dialog", {
        ref: "userPwdFormDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }